.snippet-board {
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
  position: sticky;
  top: 64px;
  backdrop-filter: blur(10px);
  max-height: calc(100vh - 64px);
  overflow: auto; /* Add this if you want scrollbars when the content is too tall */
  min-width: 250px; /* Fixed width */
  max-width: 250px; /* Fixed width */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.5);
}
  
.nav-buttons{
  display: flex;
  flex-direction: row; 
  justify-content: center;
}
  .switches{
    display:'flex';
    flex-direction: 'row';
  }

  .fullButton {
    display: 'flex';
    alignItems: 'center';
    gap: 1;
    marginBottom: 2;
    backgroundColor: 'transparent';
    color: 'white';
  }
  
  .fullButtonIcon {
    justifyContent: 'center';
    alignItems: 'center';
    width: 30;
    height: 30;
    border: '2px solid white';
    borderRadius: '4px';
    marginRight: '8px';
    color: 'white';
  }
  
  .fullButtonText {
    color: 'white';
  }
  
  .fullButtonActive {
    backgroundColor: '#f9c571';
  }
  
  .fullButtonInactive {
    backgroundColor: '#0D5842';
  }
  
  @media (max-width: 750px) {
    .snippet-board {
      position: sticky; /* Ensure it stays at the bottom */
      bottom: 0;
      min-width: 100%;
      max-width: 100%;
      flex-direction: row;
      height: 282px;
      z-index: 10; /* Ensure it stays on top */
    }
    .switches{
      flex-direction: 'column';
      width: 200px;
    }
    .clarification{
      display: none;
    }
    .buttons{
      width: 200px;
      margin-top: 20px;
      align-items: center;
    }
  }

  @media screen and (max-height: 550px) {
    .snippet-board {
      
        min-width:150px;
        max-height: 100vh;
    }
    .clarification{
      display: none;
    }
}