/* AddMaterialButton.css */
.addMaterialButton {
    background: linear-gradient(45deg, #006547, #004065);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 2px solid hsl(162, 100%, 20%);
    box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
    width: 130px;
    height: 145px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  }
  
  .addMaterialButton:hover {
    background-color: #f9c571; /* Slightly darker on hover */
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6);
  }
  
  .addMaterialIcon {
    font-size: 60px;
    color: white;
  }
  
  .addMaterialText {
    color: white;
    margin-top: 10px;
  }
  