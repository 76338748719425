.account-navigation {
    min-width: 250px;
    max-width: 250px;
    background: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
    backdrop-filter: blur(10px);
    position: sticky;
    top: 64px;
    height: calc(100vh - 64px);
    overflow:auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease; /* Smooth transition for sliding in and out */
}

.drawer {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    padding: 20px;
}

.hidden {
    transform: translateX(-100%); /* Move the navigation out of view to the left */
}

.visible {
    transform: translateX(0); /* Move the navigation into view */
}

.toggle-button {
    display: none; /* Hide toggle button on larger screens */
}

@media (max-width: 997px) {
    .account-navigation {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 1000; /* Ensure it is above other content */
    }

    .toggle-button {
        background-color: #f9c571;
        display: block;
        position: sticky;
        left: 0;
        top: 10px;
        z-index: 1001; /* Ensure it is above the navigation */
        color: #006547;
        border: 1px solid #006547;
        border-radius: 0 10px 10px 0;
        padding: 10px 15px;
        cursor: pointer;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-height: 550px) {
    .account-navigation{
        min-height: 100vh;
        max-height: 100vh;
    }
}