/* Import Inter and Oswald fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Oswald:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  font-family: 'Roboto', sans-serif; /* Set Inter as the primary font for the body */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: 'Roboto', sans-serif; /* Set Oswald for the code blocks, giving a unique style */
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
