/* NavBar.css */

.navbar { /* Semi-transparent background */
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  position: sticky;
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
  top: 0;
  width: 100%;
}

.navbar-button{
  
}
.toolbar {
  display: flex;
  justify-content: space-between; /* Ensures space between logo and buttons */
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
  height: 100%;
  padding: 8px;
}

.logo-box {
  
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  background: linear-gradient(45deg, #006547, #004065);
  max-height: 50px;
  max-width: 50px;
  margin-right: 8px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
}

.nav-title {
  color: #004065;
  font-size: 1.25rem; /* h6 equivalent */
}

.nav-buttons {
  display: flex;
  justify-content: flex-end; /* This ensures items are aligned to the right */
  align-items: center;
}

.nav-button {
  color: inherit;
  text-transform: none;
  margin: 0 8px;
}

@media screen and (max-width: 767px) {
  .nav-button {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-height: 550px) {
  .navbar {
    display: none !important;
  }
  .toolbar {
    display: none !important;
  }
}
