.title {
  color: white;
}

.progress-bar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.progress-bar-container {
  min-width: 100px;
  max-width: 500px;
  height: 20px; /* Adjusted to match the fill height */
  background-color: #e0e0de;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* Ensures the inner div doesn't exceed the container's radius */
  position: relative; /* Necessary for the percentage positioning */
}

.progress-bar-fill {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the percentage text horizontally */
  height: 100%; /* Adjusted to fill the container */
  border-radius: 5px;
  text-align: center; /* Center the percentage text */
  position: absolute; /* Necessary for absolute positioning of percentage */
  top: 0;
  left: 0;
}

.percentage {
  color: #006547; /* Green color */
  position: absolute; /* Position inside the progress bar fill */
  width: 100%; /* Take the full width of the progress bar fill */
  text-align: center; /* Center the text */
  font-weight: bold; /* Make the text bold */
  z-index: 1; /* Ensure it appears above the progress bar fill */
}

.title {
  display: flex;
  align-items: center;
}

/* Added styling for info icon */
.info-icon {
  margin-left: 5px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.info-icon{
  color: white;
}
/* Added styling for info box */
.info-icon .info-box {
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 15px;
  color: white;
  background-color: #fff1db;
  border: 1px solid #004065;
  padding: 10px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Show info box on hover */
.info-icon:hover .info-box {
  display: block;
}