.slideshow {
    border-radius: 15px;
    position: relative;
    margin: 50px;
    width: 100%; /* Adjust based on your preference */
    max-width: 1200px;
    box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

.slideshow:hover{
  box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6);
}
  
  .slide {
    display: none;
    width: 100%;
    border-radius: 15px;
  }
  
  .slide.active {
    display: block;
  }
  
  .slideshow img {
    border-radius: 15px;
    width: 100%;
    height: auto;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.5);
  }
  
  .dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }
  
  .dots .dot {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .dots .dot.active {
    background-color: #717171;
  }
  
  
  .slideshow button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
  }
  
  .slideshow button:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .slideshow button:first-of-type {
    left: 10px;
  }
  
  .slideshow button:last-of-type {
    right: 10px;
  }

  @media screen and (max-width: 767px){
  .slideshow{
    width: 100%;
  }
}