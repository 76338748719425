.faq-item {
  margin-bottom: 20px;
  background: linear-gradient(45deg, #006547, #004065);
  border-radius: 5px;
  min-width: 100%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.faq-item:hover {
  background-color: transparent;
}

.faq-question {
  color: #fff;
  font-size: 25px;
  margin: 0;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure full width */
}

.faq-answer {
  display: none;
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
}

.faq-answer.expanded {
  display: block;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  transition: transform 0.3s;
}

.triangle.down {
  transform: rotate(0deg);
}

.triangle.up {
  transform: rotate(180deg);
}

.w-layout-blockcontainer {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center; /* Center align the children */
}

.container-3 {
  background: rgba(255, 255, 255, 0.5);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  flex: 1;
  order: 0;
  align-self: center;
  justify-content: right;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.container-3:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6);
}

.container-3-internal {
  width: 100%;
  max-width: 600px;
}

.heading-2 {
  color: #004065;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center; /* Center align the heading */
}
