 
  h1 {
    color: #f9c571;
    font-size: 2rem;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
  }
  .top-bar-userpage{
    align-items: center; /* Vertical center alignment */
    display: flex;
    flex-direction: row;
    font-size: 2rem;
    margin-left: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .account-navigation{
    min-width: 250px;
    max-width: 250px;
    background: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
    backdrop-filter: blur(10px);
    position: sticky;
    top: 64px;
    max-height: calc(100vh - 64px);
     /* Adjust width as necessary */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
}


  .background{
    background: radial-gradient(circle 1200px at 50% -400px, #F9A620, white);
    background-size: 100% 100%;
    background-attachment: fixed;
    background-clip: padding-box;
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  input[type="file"] {
    margin-top: 20px;
    margin-bottom: 20px;
  } 

  .v-box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top:20px;
    margin-bottom:20px;
    align-items: center;
  }

  .h-box {
    margin-top: 64px; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-grow: 1;
    justify-content: center; /* Space out the items evenly */
}


  .snippet-board{
    margin: 0;
    padding: 0;
  }

  .page-navigation{
    grid-column: 1; 
    grid-row: 1;
    height: 100%;
    margin-top: 20px;
    background: linear-gradient(45deg, #006547, #004065);
    border-radius: 10px;
    display: flex;
    flex-direction: vertical;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 5px 10px rgba(0, 64, 101, 0.5);
  }

  .blurred-page{
    filter: blur(0px);
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
  }

  .blurred-pdf {  
    /* Adjust the blur amount as needed */
    display: flex;
    justify-content: center;
    position: relative; /* Not strictly necessary, but good for clarity */
    z-index: 1; /* Lower than overlay-canvas */
  }

  .document {
    grid-column: 1; 
    grid-row: 2;
    position: relative; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .input_pdf_viewer{
    flex-grow:1;
  }

  .pdf-viewer {
    display: grid;
    grid-template-columns: 1fr; /* Adjust to fit single column layout */
    grid-template-rows: 70px auto;
    row-gap: 15px; /* Gap between rows */
    column-gap: 20px; /* Gap between columns */
    position: relative; /* Positioning context */
    flex-grow: 1; /* Allow this element to grow and fill available space */
    flex-shrink: 1; /* Allow this element to shrink if necessary */
    margin-bottom: 20px;
    height: 100%;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}
  
.overlay-canvas {
    left: 50%; 
    transform: translateX(-50%); /* these two lines assure that the canvas is centered (and absolute so it can overlap) */ 
    position: absolute;
    top: 0;
    width: 100%; /* Ensure it covers the entire pdf-viewer area */
    height: 100%; /* Ensure it covers the entire pdf-viewer area */
    pointer-events: none; /* Allows clicks to pass through to the PDF underneath */
    z-index: 3; /* Higher than blurred-pdf */
  }
  .middle-canvas{
    left: 50%; 
    transform: translateX(-50%); /* these two lines assure that the canvas is centered (and absolute so it can overlap) */ 
    position: absolute;
    top: 0;
    width: 100%; /* Ensure it covers the entire pdf-viewer area */
    height: 100%; /* Ensure it covers the entire pdf-viewer area */
    pointer-events: none; 
    z-index: 2
  }

.arrows-and-page{
  min-width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-button {
  /* Style the button as neede
  d */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Example background color */
  color: white; /* Text color */
  font-size: 20px; /* Adjust font size as needed */
  border-radius: 8px;
  height: 30px;
  /* Create a triangle shape (right arrow) using pseudo-elements */
  position: relative;
}


/* Adjust hover styles as needed */
.nav-button:hover {
  background: linear-gradient(45deg, #006547, #004065); /* Example hover background color */
}

.chunk {
  position: relative;
  /* Add other styling as needed */
}



@media (max-width: 750px) {
  .h-box {
    flex-direction: column;
    padding-bottom: 282px;
  }

  

  .input_pdf_viewer {
    margin-bottom: 100px; /* Adjust this value to provide space for the snippet board */
    overflow-y: auto; /* Enable scrolling if content overflows */
    height: calc(100vh - 100px); /* Ensure the height accounts for the snippet board */
  }
}

@media screen and (max-height: 550px) {
  .h-box{
    margin-top: 0px; 
    margin-left:0px;
    gap: 5px;
  }

  .account-navigation{
    top: 0;
  }
}