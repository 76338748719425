.custom-table {
    width: 67%;
    margin: auto;
    margin-top: 60px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-table:hover{
    box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6);
  }

.custom-table th,
.custom-table td {
    padding: 8px;
    text-align: center;
    border-bottom: 2px solid white;
    border-right: 2px solid #f9c571;
    font-size: 20px;
    box-sizing: border-box;
}

.custom-table th {
    font-size: 22px;
}

.custom-table th:last-child,
.custom-table td:last-child {
    border-right: none;
}

.custom-table tr:last-child td {
    border-bottom: none;
}

.custom-table .tick {
    color: #006547;
}

.custom-table .cross {
    color: red;
}

.custom-table tr:hover {
    background: linear-gradient(45deg, #006547, #004065);
}
.custom-table tbody tr:hover .tick {
    color: white;
}
.custom-table tbody tr:hover .benefit {
    color: white;
}

.custom-table tbody tr:first-child td,
.custom-table thead th {
    border-top: none;
}

.custom-table tbody tr td,
.custom-table thead th {
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Style for the table body rows */
.custom-table tbody tr {
    background: rgba(255, 255, 255, 0.5);
    color: #f9c571;
}

.custom-table tbody tr td {
    border-bottom: 2px solid #f9c571; /* Orange horizontal lines */
    border-right: 2px solid #f9c571;  /* Orange vertical lines */
}

.custom-table thead tr{
    background: linear-gradient(45deg, #006547, #004065);
}
/* Retain the style for the topmost row */
.custom-table thead th {
    color: white;
}

.custom-table tbody tr:last-child td {
    border-bottom: none; /* Removes the bottom border from the last row cells */
}

@media screen and (max-width: 479px) {
    .custom-table th {
        font-size: 16px;
    }
    .custom-table td {
        font-size: 14px;
    }
    .custom-table{
        margin-left: 5px;
        margin-right: 5px;
    }
}
  