

:root {
  --color1: #F9A620;
  --color2: #006547;
  --color3: #fff6fa;
  --color4: #004065;
  --color5: linear-gradient(45deg, #006547, #004065);
  --font-gradient: linear-gradient(45deg, #006547, #004065);
  --white: #424242;
  --black: black;
}

.w-layout-vflex {
  padding-top: 40px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between; /* Align items to maximally push them apart */
  display: flex;
}

.how-icon{
 font-size:36px;
}

.w-layout-blockcontainer {
  max-width: 60%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;       /* Updated display property */
  justify-content: flex-end; /* Aligns children to the right */
}




.section {
  height: 50px;
  object-fit: fill;
  background-color: #00274a;
  border-radius: 0 0 15px 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: relative;
  top: 0;
}

.section.head {
  background-color: var(--color1);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.image {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 5%;
  right: auto;
}

.heading {
  text-align: center;
  justify-content: flex-end;
  display: flex;
}

.flex-block {
  object-fit: fill;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  position: static;
}

.change-rectangle{
  width: 400px;
  background-color: gray;
}

.div-block {
  width: 100%;
  object-fit: contain;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5%;
  margin-top: 60px;
  margin-bottom: 40px;
}

.flex-block-2 {
  width: 100%;
  height: auto;
  align-self: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.div-block-2 {
  width:60%;
  height: auto;
  border-radius: 15px 0 0 15px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.div-block-2-internal{
  flex-direction: column;
  justify-content: center;
  display: flex;
  max-width: 1000px;
}


.flex-block-10{
  align-items: center;
  margin: 20px;
  position: static;
  margin-bottom: 40px;
}

.partners{
 width: 100%;
 display: flex; 
 flex-direction: row;
 flex-wrap: wrap;
 gap: 15%;
 justify-content:center;
 align-items:center;
 margin-bottom: 80px;
}

.div-block-2.colorclass {
  max-width: 60%;
  height: 100%;
  align-self: center;
  background: rgba(255, 255, 255, 0.5); 
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for size and shadow */
}

.div-block-2.colorclass:hover {
  transform: scale(1.05); /* Slightly increase the size */
  box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6); /* Stronger glow effect */
}

.signature {
  position: absolute; /* Positions the signature absolutely within the rectangle */
  bottom: 10px; /* Distance from the bottom of the rectangle */
  right: 10px; /* Distance from the right of the rectangle */
  color: var(--color1); /* Optional: ensures the text color is white */
}
.signature:hover{
  color: white;
}

.review{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.rectangle.colorclass {
  width: 360px;
  border-radius: 15px;
  margin: 20px;
  height: 180px; /* Fixed height; adjust as necessary */
  align-self: center;
  background: rgba(255, 255, 255, 0.5); /* Default background color */
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
  text-align: center; /* Centers the text horizontally */
  color: var(--color1); /* Sets the text color to white */
  display: flex;
  flex-direction: column; /* Aligns children elements in a column */
  justify-content: center; /* Centers content vertically */
  position: relative; /* Needed to position the signature absolutely within the rectangle */
  transition: background 0.5s ease; /* Smooth transition effect */
}

.rectangle.colorclass:hover {
  background:var(--font-gradient);
  background: linear-gradient(45deg, #006547, #004065); /* Gradient background on hover */
}
.div-block-3 {
  width: 100%;
  height: auto;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.heading-2 {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center; /* Center align the heading */
}


.figure {
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  margin-left: 20px;
  padding-right: 20px;
}

.image-2 {
  margin-left: 40px;
  margin-right: 40px;
  display: block;
}

.text-span {
  color: var(--color4);
  padding-left: 20px;
}

.about-me{
  color: var(--color4);
  font-size:26px;
  line-height: 30px;
  margin-left: 20px;
}

.list-item {
  color: var(--color4);
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 30px;
}

.list-item-2, .list-item-3 {
  color: var(--color4);
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 25px;
}

.heading-3 {
  margin-top: 20px;
  color: white;
  text-align: center;
}

.text-span-2 {
  margin-top: 20px;
  font-size: 64px;
  line-height: 1.5;
  text-align: center;
}
.text-gradient{
  background: linear-gradient(45deg, #006547, #004065);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-span-3{
  margin-top: 20px;
  font-size: 36px;
  color: white;
  margin-top: 20px;
}

.button, .button-2, .button-3 {
  background-color: rgba(0, 0, 0, 0);
  font-size: 22px;
}
.how-item{
  font-size: 26px;
}
.how-item-2{
  font-size: 18px;
}

.bold-text-2 {
  background-color: rgba(0, 0, 0, 0);
}

.div-block-4 {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  height: auto; 
  margin-top: 40px;
  margin-bottom: 80px;
}

.div-block-5 {
  max-width: 80%;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.div-block-9 {
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.button-4 {
  width: 100px;
  background: linear-gradient(45deg, #006547, #004065);
  text-align: center;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.image-3 {
  width: 250px;
  height: 250px;
  margin-left: 40px;
  margin-right: 40px;
}

.div-block-6 {
  width: 80%;
  height: auto;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.container-2 {
  width: 100%;
  background-color: var(--color1);
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  order: 0;
  align-self: flex-end;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0%;
}

.heading-4 {
  color: var(--color4);
  font-size: 25px;
}

.text-block {
  color: var(--color4);
  font-size: 16px;
  margin-left: 10px;
}

.heading-5 {
  color: #fff;
  font-size: 25px;
}

.text-block-2 {
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
}

.heading-6 {
  color: #fff;
  font-size: 25px;
}

.text-block-3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  margin-left: 10px;
}

.heading-7 {
  color: var(--color2);
  margin-top: 40px;
  text-align: center;
}

.div-block-7 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-8 {
  width: 33%;
  height: 300px;
  background-color: #00274a;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
}

.body {
  background-color: #fff;
  background-position: 0 0;
  background-size: 100%;
  background-attachment: fixed;
}

.gradient-background {
  background: radial-gradient(circle 1200px at 50% -400px, #F9A620, white);
  background-size: 100% 100%;
  /*animation: gradient-animation 15s ease infinite;*/
  -webkit-text-fill-color: inherit;
  /*background-image: url('../images/4850037.jpg');*/
  background-position: 0 0;
  background-attachment: fixed;
  background-clip: padding-box;
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
/*.gradient-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 1px, transparent 1px);
  background-size: 10px 10px;
  pointer-events: none;
  mask-image: linear-gradient(to bottom, black, transparent);
}*/


.body-2 {
  
}


.text-span-3 {/* Example gradient colors */
  margin-top: 20px;
  color: white;
}

.flex-block-3 {
  height: 100%;
}

.form-block {
  margin-left: 20px;
}

.field-label, .field-label-2 {
  color: #fff;
}

.submit-button {
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-field, .text-field-2 {
  border-radius: 15px;
}

.heading-8 {
  flex: 0 auto;
  align-self: center;
}

.heading-9 {
  color: var(--color2);
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 40px;
  display: flex;
}

.flex-block-4 {
  justify-content: space-around;
}

.image-6 {
  width: 70px;
}

.paragraph, .paragraph-2 {
  color: #00274a;
  font-size: 22px;
}

.flex-block-5, .flex-block-6 {
  justify-content: center;
  align-items: center;
}

.paragraph-3 {
  color: #00274a;
  font-size: 20px;
}

.flex-block-7 {
  justify-content: center;
  align-self: center;
  align-items: center;
}

.paragraph-4 {
  color: #00274a;
  font-size: 20px;
}

.list-flex-container {
  display: flex; /* Establishes a flex container */
  align-items: center; /* Vertically centers the items in the container */
  gap: 10px; /* Provides some space between the icon and the text */
}

.flex-block-8 {
  justify-content: center;
  align-items: center;
}

.three-step-icon{
  font-size: 84px;
  margin-bottom:50px;
}

.image-8 {
  width: 70px;
  height: 70px;
}

.how-to-image{
  height: 35px;
  width: 35px;
  flex-shrink: 0;
  margin-left:10px;
  margin-right:10px;
}
.image-9 {
  width: 70px;
  height: 70px;
  color: var(--color2);
}

.image-10 {
  width: 70px;
  height: 70px;
}

.image-11 {
  width: 250px;
  height: 250px;
}

.bold-text-3, .bold-text-4, .bold-text-5, .bold-text-6 {
  color: var(--color2);
}

.image-12 {
  width: 70px;
  height: 70px;
}

.image-13 {
  width: 70px;
  height: 70px;
}

.image-14, .image-15 {
  width: 70px;
  height: 70px;
}

.image-16 {
  height: 310px;
  width: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 70px;
  line-height: 70px;
 /*filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5)); */
}

.image-20 {
  border-radius: 15px;
  height: 260px;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 70px;
  line-height: 70px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
}

.image-20:hover {
  transform: scale(1.05); /* Slightly increase the size */
  box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6); /* Stronger glow effect */
}


.image-17 {
  height: 310px;
  width: auto;
  flex: 0 auto;
  order: 0;
  border-radius: 15px;
  align-self: center;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-17:hover {
  transform: scale(1.05); /* Slightly increase the size */
  box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6); /* Stronger glow effect */
}

.list {
  list-style-type: none; /* Removes the default bullet points */
  padding-left: 20px;
}

.heading-10 {
  align-self: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.flex-block-9 {
  width: 100%;
  flex: 0 auto;
  justify-content: flex-start;
  align-self: auto;
  align-items: center;
}

.container-3 {
  background: rgba(255, 255, 255, 0.5); 
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  flex: 1;
  order: 0;
  align-self: center;
  justify-content: right;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 0 10px rgba(0, 64, 101, 0.5), 0 0 20px rgba(0, 64, 101, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for size and shadow */
}

.container-3:hover {
  transform: scale(1.05); /* Slightly increase the size */
  box-shadow: 0 0 20px rgba(0, 64, 101, 0.8), 0 0 30px rgba(0, 64, 101, 0.6); /* Stronger glow effect */
}
.container-3-internal{
  max-width: 1000px;
}

@media screen and (max-width: 991px) {
  .div-block {
    /* Add overflow-x: auto; to make the content scrollable horizontally */
    overflow-x: auto;
    /* Ensure the height of the container is enough to accommodate the content */
    height: auto;
  }

  .partners{
    gap: 40px;
    margin-bottom:60px;
  }

  .heading-3 {

  }

  .text-span-2 {
    font-size:48px;
  }

  .text-span-3 {
    font-size:24px;
  }
  
  .button {
    background-color: rgba(0, 0, 0, 0);
    margin-left: 10px;
    margin-right: 10px;
  }

  .button-2, .button-3 {
    background-color: rgba(0, 0, 0, 0);
    margin-left: 10px;
    margin-right: 10px;
    font-size: 22px;
  }

  .div-block-9{
    margin-bottom: 0px;
  }
  .div-block-10{
    gap: 120px;
    margin-bottom: 120px;
  }

  .bold-text {
    font-size: 22px;
  }
  .image-16 {/* Adjust height for medium screens */
    width: 20%;
    height: auto;/* Adjust width for medium screens */
    margin-left: auto; /* Center the image horizontally */
    margin-right: 200px; /* Center the image horizontally */
  }


  .div-block-2.colorclass {
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
    padding: 10px; /* Adjust padding */
  }
}
@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
  .image-17{ /* Adjust height for medium screens */
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    height: auto;
  }
  .w-layout-hflex {
    gap: 40px;
    flex-direction: column; /* Stack flex items vertically on small screens */
    align-items: center; /* Center-align items */
  }
  .div-block-5 {
    width: 80%;
    max-width: 1200px;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  .flex-block-2{
    margin-top:20px;
  }
  .image-16 {
    width: 50%; /* Set image width to 100% of its container */
    max-width: 200px;
    height: auto; /* Keep the image's aspect ratio */
    padding: 0; /* Remove padding */
    margin: 0 0 60px 0; /* Add some margin at the bottom */
    order: -1; /* Place image above the text */
  }
  .flex-block-4 {
    justify-content: space-around;
    gap: 20px;
  }
  .div-block-2.colorclass {
    width: auto; /* Set width to auto to fill the container */
    border-radius: 15px; /* Adjust border-radius for aesthetics */
    padding: 15px; /* Add padding to avoid text touching the edges */
  }

  .list {
    list-style-type: none; /* Removes the default bullet points */
    padding-left: 0px;
  }
  
  .container-3 {
    margin-left: 5px;
    margin-right: 5px;
    max-width: none;
    background: rgba(255, 255, 255, 0.5); 
    border-radius: 15px;
    flex: 0 auto;
    order: 0;
    align-self: center;
  }
  .div-block-4{
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    height: auto; 
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .heading-9{
  color: var(--color2);
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  display: flex;
  }
}

@media screen and (max-width: 479px) {
  .text-span-2 {
    font-size: 36px;
  }
  .text-span-3{
    font-size: 26px;
  }
  .image {
    width: 50px;
    height: 50px;
  }
}
