.footer-sticky{
    width: auto;
    position: fixed;
    left: 0;
    bottom: 0;
}

.gradient-background {
    background: radial-gradient(circle at 35% 50%, #f9c571 30%, #f9c571 30%);
    background-size: 130% 130%;
    animation: gradient-animation 15s ease infinite;
    -webkit-text-fill-color: inherit;
    /*background-image: url('../images/4850037.jpg');*/
    background-position: 0 0;
    background-attachment: fixed;
    background-clip: padding-box;
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
  }